import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function addressEdit({id, addressType, firstName, lastName, streetAddress, details, city, state, zip, mobile}) {
  return omniFetch({
    url: config.v1Path + config.addressEditPath,
    method: "post",
    params: {id, addressType, firstName, lastName, streetAddress, details, city, state, zip, mobile}
    //addressType 收货地址：1 Shipping，支付信用卡地址: 2 Billing
  })
}

export function addressQuery({addressType}) {
  return omniFetch({
    url: config.v1Path + config.addressQueryPath,
    method: "get",
    params: {addressType}
    //addressType 收货地址：1 Shipping，支付信用卡地址: 2 Billing
  })
}
